import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, CircularProgress, Paper, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Add as AddIcon, ChevronLeft, ChevronRight } from '@mui/icons-material';
import DevisForm from '../components/DevisForm';
import DevisList from '../components/DevisList';
import { getDevis, addDevis } from '../services/devisService';
import { useLocation } from 'react-router-dom';
import { format, addMonths, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { fr } from 'date-fns/locale';

const MonthSelector = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

const DevisPage = () => {
  const location = useLocation();
  const highlightId = location.state?.highlightId;
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [devisList, setDevisList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  useEffect(() => {
    fetchDevis();
  }, [currentMonth]);

  const fetchDevis = async () => {
    try {
      setLoading(true);
      setError(null);
      const start = startOfMonth(currentMonth);
      const end = endOfMonth(currentMonth);
      const response = await getDevis(start, end);
      // Filtrez les devis pour n'inclure que ceux du mois courant
      const filteredDevis = response.filter(devis => {
        const devisDate = new Date(devis.date);
        return devisDate >= start && devisDate <= end;
      });
      setDevisList(filteredDevis);
    } catch (err) {
      console.error("Erreur lors de la récupération des devis:", err);
      setError("Impossible de charger les devis. Veuillez réessayer.");
      setDevisList([]);
    } finally {
      setLoading(false);
    }
  };
  
  const handleDevisAdded = async (newDevisData) => {
    try {
      setLoading(true);
      const response = await addDevis(newDevisData);
      setDevisList(prevDevis => [response.data, ...prevDevis]);
      setIsFormOpen(false);
      setError(null);
    } catch (err) {
      console.error("Erreur lors de l'ajout du devis:", err);
      setError("Impossible d'ajouter le devis. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousMonth = () => {
    setCurrentMonth(prevMonth => subMonths(prevMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(prevMonth => addMonths(prevMonth, 1));
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', padding: 3 }}>
      <Box sx={{ 
        mb: 3, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderRadius: 1,
        padding: 2,
        boxShadow: 1
      }}>
        <Typography variant="h5" component="h2">Devis</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          onClick={() => setIsFormOpen(true)}
        >
          Ajouter un devis
        </Button>
      </Box>
      <MonthSelector>
        <IconButton onClick={handlePreviousMonth}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h6">
          {format(currentMonth, 'MMMM yyyy', { locale: fr }).replace(/^\w/, c => c.toUpperCase())}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <ChevronRight />
        </IconButton>
      </MonthSelector>
      {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {loading ? (
          <CircularProgress sx={{ m: 'auto' }} />
        ) : (
          <DevisList devisList={devisList} setDevisList={setDevisList} highlightId={highlightId}/>
        )}
      </Box>
      <Dialog open={isFormOpen} onClose={() => setIsFormOpen(false)}>
        <DialogTitle>Ajouter un devis</DialogTitle>
        <DialogContent>
          <DevisForm 
            onDevisAdded={handleDevisAdded}
            onCancel={() => setIsFormOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DevisPage;