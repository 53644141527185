import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Chip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const DevisList = ({ devisList, setDevisList, highlightId }) => {
  if (!Array.isArray(devisList) || devisList.length === 0) {
    return <Typography>Aucun devis trouvé pour cette période.</Typography>;
  }

const getStatusColor = (status) => {
  switch (status) {
    case '2': return 'warning';
    case '3': return 'info';
    case '4': return 'success';
    default: return 'default';
  }
};

const getStatusText = (status) => {
  switch (status) {
    case '2': return 'Vu';
    case '3': return 'Approuvé';
    case '4': return 'Converti';
    default: return 'Inconnu';
  }
};

  const handleEdit = (id) => {
    // Implémenter la logique d'édition ici
    console.log(`Édition du devis avec l'ID : ${id}`);
    // Par exemple, vous pourriez ouvrir un modal d'édition ou naviguer vers une page d'édition
  };

  const handleDelete = (id) => {
    // Implémenter la logique de suppression ici
    console.log(`Suppression du devis avec l'ID : ${id}`);
    // Par exemple, vous pourriez afficher une confirmation puis supprimer le devis
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce devis ?')) {
      setDevisList(devisList.filter(devis => devis.id !== id));
      // Ici, vous devriez également appeler une API pour supprimer le devis du backend
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Numéro</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Montant</TableCell>
            <TableCell>Statut</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devisList.map((devis) => (
            <TableRow 
              key={devis.id} 
              sx={{ 
                backgroundColor: devis.id === highlightId ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
                '&:last-child td, &:last-child th': { border: 0 } 
              }}
            >
              <TableCell>{devis.number}</TableCell>
              <TableCell>{devis.clientName}</TableCell>
              <TableCell>{format(new Date(devis.date), 'dd/MM/yyyy', { locale: fr })}</TableCell>
              <TableCell>{devis.amount} €</TableCell>
              <TableCell>
                <Chip 
                  label={getStatusText(devis.status)} 
                  color={getStatusColor(devis.status)}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(devis.id)} size="small">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(devis.id)} size="small">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DevisList;