import React, { useState } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, IconButton, Typography, Box, Dialog, DialogTitle, DialogContent
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RecurringExpenseForm from './RecurringExpenseForm';
import { deleteRecurringExpense, updateRecurringExpense } from '../services/recurringExpenseService';
import { useLocation } from 'react-router-dom';
import { alpha } from '@mui/material/styles';

function RecurringExpenseList({ recurringExpenses, setRecurringExpenses }) {
  const [editingExpense, setEditingExpense] = useState(null);
  const location = useLocation();
  const highlightId = location.state?.highlightId;

  const handleDelete = async (id) => {
    try {
      console.log(`Deleting expense with id: ${id}`);
      await deleteRecurringExpense(id);
      console.log('Delete successful');
      setRecurringExpenses(recurringExpenses.filter(expense => expense._id !== id));
    } catch (error) {
      console.error("Erreur détaillée lors de la suppression du frais récurrent :", error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      }
    }
  };
  
  

  const handleEdit = (expense) => {
    setEditingExpense(expense);
  };

  const handleEditSubmit = async (updatedExpense) => {
    try {
      const response = await updateRecurringExpense(updatedExpense._id, updatedExpense);
      setRecurringExpenses(recurringExpenses.map(exp => exp._id === updatedExpense._id ? response.data : exp));
      setEditingExpense(null);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du frais récurrent :", error);
    }
  };

  const calculateMonthlyTotal = (expenses) => {
    return expenses.reduce((total, expense) => {
      switch(expense.frequency) {
        case 'daily':
          return total + (expense.amount * 30); // Supposons 30 jours par mois en moyenne
        case 'weekly':
          return total + (expense.amount * 4); // Supposons 4 semaines par mois
        case 'monthly':
          return total + expense.amount;
        case 'yearly':
          return total + (expense.amount / 12);
        case 'bimonthly':
          return total + (expense.amount / 2);
        default:
          return total;
      }
    }, 0);
  };

  const totalMonthlyRecurringExpenses = calculateMonthlyTotal(recurringExpenses);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderRadius: 1, overflow: 'hidden', boxShadow: 1 }}>
      <TableContainer component={Paper} sx={{ flexGrow: 1, overflow: 'auto', maxHeight: 'calc(100% - 60px)' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Montant</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Fréquence</TableCell>
              <TableCell>Montant mensuel estimé</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recurringExpenses.map((expense) => {
              let monthlyAmount;
              switch(expense.frequency) {
                case 'daily':
                  monthlyAmount = expense.amount * 30;
                  break;
                case 'weekly':
                  monthlyAmount = expense.amount * 4;
                  break;
                case 'monthly':
                  monthlyAmount = expense.amount;
                  break;
                case 'yearly':
                  monthlyAmount = expense.amount / 12;
                  break;
                case 'bimonthly':
                  monthlyAmount = expense.amount / 2;
                  break;
                default:
                  monthlyAmount = 0;
              }
              
              return (
                <TableRow 
                    key={expense._id}
                    sx={(theme) => ({
                        backgroundColor: expense._id === highlightId
                        ? '#A2D6F9' // Bleu clair personnalisé
                        : 'inherit',
                        '&:hover': {
                        backgroundColor: expense._id === highlightId
                            ? '#1E96FC' // Bleu clair plus foncé au survol
                            : alpha(theme.palette.action.hover, 0.1),
                        }
                    })}
                    >
                  <TableCell>{expense.amount.toFixed(2)}€</TableCell>
                  <TableCell>{expense.description}</TableCell>
                  <TableCell>{expense.frequency}</TableCell>
                  <TableCell>{monthlyAmount.toFixed(2)}€</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(expense)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(expense._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ 
        p: 2, 
        backgroundColor: 'background.paper', 
        borderTop: 1, 
        borderColor: 'divider',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
      }}>
        <Typography variant="h6">
          Total mensuel estimé des frais récurrents : {totalMonthlyRecurringExpenses.toFixed(2)}€/mois
        </Typography>
      </Box>
      <Dialog open={!!editingExpense} onClose={() => setEditingExpense(null)}>
        <DialogTitle>Modifier le frais récurrent</DialogTitle>
        <DialogContent>
          {editingExpense && (
            <RecurringExpenseForm
              initialData={editingExpense}
              onRecurringExpenseAdded={handleEditSubmit}
              onCancel={() => setEditingExpense(null)}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default RecurringExpenseList;