import api from '../services/api';

export const refreshToken = async () => {
  const currentToken = localStorage.getItem('token');
  try {
    const response = await api.post('/auth/refresh', { token: currentToken });
    localStorage.setItem('token', response.data.token);
    return response.data.token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    // Handle error (e.g., redirect to login page)
    return null;
  }
};

export const setupTokenRefresh = () => {
  // Check token every 10 minutes
  setInterval(async () => {
    const token = await refreshToken();
    if (!token) {
      // Token refresh failed, redirect to login
      window.location.href = '/login';
    }
  }, 10 * 60 * 1000);
};

export const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
  };
  
  export const logout = () => {
    localStorage.removeItem('token');
  };