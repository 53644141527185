import api from './api';

export const getRecurringExpenses = () => {
    return api.get('/recurring-expenses');
  };
  
export const calculateMonthlyTotal = (expenses) => {
  return expenses.reduce((total, expense) => {
    switch(expense.frequency) {
      case 'daily':
        return total + (expense.amount * 30);
      case 'weekly':
        return total + (expense.amount * 4);
      case 'monthly':
        return total + expense.amount;
      case 'yearly':
        return total + (expense.amount / 12);
      case 'bimonthly':
        return total + (expense.amount / 2);
      default:
        return total;
    }
  }, 0);
};

export const addRecurringExpense = (expenseData) => api.post('/recurring-expenses', expenseData);

export const updateRecurringExpense = (id, expenseData) => {
  console.log(`Updating recurring expense with id: ${id}`, expenseData);
  return api.put(`/recurring-expenses/${id}`, expenseData);
};

export const deleteRecurringExpense = (id) => {
  console.log(`Attempting to delete recurring expense with id: ${id}`);
  return api.delete(`/recurring-expenses/${id}`)
    .then(response => {
      console.log('Delete response:', response);
      return response;
    })
    .catch(error => {
      console.error('Delete error:', error.response || error);
      throw error;
    });
};



export const searchRecurringExpenses = async (term) => {
    try {
      const response = await api.get(`/recurring-expenses/search?term=${term}`);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la recherche des frais récurrents:", error);
      throw error;
    }
  };
