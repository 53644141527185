import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Box, MenuItem } from '@mui/material';

const RecurringExpenseForm = ({ initialData, onRecurringExpenseAdded, onCancel }) => {
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [frequency, setFrequency] = useState('monthly');

  useEffect(() => {
    if (initialData) {
      setAmount(initialData.amount.toString());
      setDescription(initialData.description);
      setFrequency(initialData.frequency);
    }
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const expenseData = {
      amount: Number(amount),
      description,
      frequency
    };
    if (initialData) {
      expenseData._id = initialData._id;
    }
    onRecurringExpenseAdded(expenseData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Montant"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            label="Fréquence"
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            required
          >
            <MenuItem value="daily">Quotidien</MenuItem>
            <MenuItem value="weekly">Hebdomadaire</MenuItem>
            <MenuItem value="monthly">Mensuel</MenuItem>
            <MenuItem value="yearly">Annuel</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onCancel} sx={{ mr: 1 }}>Annuler</Button>
        <Button type="submit" variant="contained" color="primary">
          {initialData ? 'Modifier' : 'Ajouter'}
        </Button>
      </Box>
    </Box>
  );
};

export default RecurringExpenseForm;
