import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, CircularProgress } from '@mui/material';
import RecurringExpenseList from '../components/RecurringExpenseList';
import RecurringExpenseForm from '../components/RecurringExpenseForm';
import { Add as AddIcon } from '@mui/icons-material';
import { getRecurringExpenses, addRecurringExpense } from '../services/recurringExpenseService';

const RecurringExpensesPage = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [recurringExpenses, setRecurringExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchRecurringExpenses();
  }, []);

  const fetchRecurringExpenses = async () => {
    try {
      setLoading(true);
      const response = await getRecurringExpenses();
      setRecurringExpenses(response.data);
      setError(null);
    } catch (err) {
      console.error("Erreur lors de la récupération des frais récurrents:", err);
      setError("Impossible de charger les frais récurrents. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const handleRecurringExpenseAdded = async (newRecurringExpenseData) => {
    try {
      setLoading(true);
      console.log("Données à envoyer:", newRecurringExpenseData);
      const response = await addRecurringExpense(newRecurringExpenseData);
      console.log("Réponse reçue:", response);
      setRecurringExpenses([response.data, ...recurringExpenses]);
      setIsFormOpen(false);
      setError(null);
    } catch (err) {
      console.error("Erreur complète:", err);
      if (err.response) {
        console.error("Données de réponse d'erreur:", err.response.data);
      }
      setError("Impossible d'ajouter le frais récurrent. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', padding: 3 }}>
      <Box sx={{ 
        mb: 3, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderRadius: 1,
        padding: 2,
        boxShadow: 1
      }}>
        <Typography variant="h5" component="h2">Frais récurrents</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          onClick={() => setIsFormOpen(true)}
        >
          Ajouter un frais récurrent
        </Button>
      </Box>
      {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {loading ? (
          <CircularProgress sx={{ m: 'auto' }} />
        ) : (
          <RecurringExpenseList recurringExpenses={recurringExpenses} setRecurringExpenses={setRecurringExpenses} />
        )}
      </Box>
      <Dialog open={isFormOpen} onClose={() => setIsFormOpen(false)}>
        <DialogTitle>Ajouter un frais récurrent</DialogTitle>
        <DialogContent>
          <RecurringExpenseForm 
            onRecurringExpenseAdded={handleRecurringExpenseAdded}
            onCancel={() => setIsFormOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default RecurringExpensesPage;
