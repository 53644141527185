import React, { useState } from 'react';
import { TextField, Button, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import fr from 'date-fns/locale/fr';

const DevisForm = ({ onDevisAdded, onCancel }) => {
  const [formData, setFormData] = useState({
    number: '',
    client: '',
    amount: '',
    status: 'draft',
    date: new Date(),
    validUntil: new Date(),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDateChange = (name) => (date) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: date
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onDevisAdded(formData);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            name="number"
            label="Numéro du devis"
            value={formData.number}
            onChange={handleChange}
            required
          />
          <TextField
            name="client"
            label="Client"
            value={formData.client}
            onChange={handleChange}
            required
          />
          <TextField
            name="amount"
            label="Montant"
            type="number"
            value={formData.amount}
            onChange={handleChange}
            required
          />
          <FormControl fullWidth>
            <InputLabel>Statut</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
              required
            >
              <MenuItem value="draft">Brouillon</MenuItem>
              <MenuItem value="sent">Envoyé</MenuItem>
              <MenuItem value="accepted">Accepté</MenuItem>
              <MenuItem value="rejected">Refusé</MenuItem>
            </Select>
          </FormControl>
          <DatePicker
            label="Date du devis"
            value={formData.date}
            onChange={handleDateChange('date')}
          />
          <DatePicker
            label="Valide jusqu'au"
            value={formData.validUntil}
            onChange={handleDateChange('validUntil')}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
            <Button onClick={onCancel}>Annuler</Button>
            <Button type="submit" variant="contained" color="primary">
              Ajouter le devis
            </Button>
          </Box>
        </Box>
      </form>
    </LocalizationProvider>
  );
};

export default DevisForm;