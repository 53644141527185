import React, { useEffect, useState, useCallback } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, IconButton, Typography, Box, Dialog, DialogTitle, DialogContent,
  Switch, alpha, DialogActions, Button, CircularProgress, useTheme, useMediaQuery, 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloseIcon from '@mui/icons-material/Close';
import PaymentIcon from '@mui/icons-material/Payment';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import ExpenseForm from './ExpenseForm';
import { payExpense, getExpenses, updateExpenseStatus } from '../services/expenseService';
import api from '../services/api';


const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function ExpenseList({ expenses, setExpenses }) {
  const [editingExpense, setEditingExpense] = useState(null);
  const [error, setError] = useState(null);
  const [receiptUrl, setReceiptUrl] = useState(null);
  const [unlockDialogOpen, setUnlockDialogOpen] = useState(false);
  const [currentExpense, setCurrentExpense] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const highlightId = location.state?.highlightId;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const fetchExpenses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No token found. Please log in again.');
        return;
      }

      const response = await api.get('/expenses');
      setExpenses(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des dépenses :", error.response ? error.response.data : error.message);
      setError('Failed to fetch expenses. Please try again.');
    }
  }, [setExpenses]);

  useEffect(() => {
    fetchExpenses();
  }, [fetchExpenses]);

  const handleDelete = async (id) => {
    try {
      await api.delete(`/expenses/${id}`);
      setExpenses(prevExpenses => prevExpenses.filter(expense => expense._id !== id));
    } catch (error) {
      console.error("Erreur lors de la suppression de la dépense :", error);
    }
  };

  const handleUnlock = (expense) => {
    setCurrentExpense(expense);
    setUnlockDialogOpen(true);
  };

  const handleUnlockConfirm = async () => {
    try {
      const updatedExpense = await updateExpenseStatus(currentExpense._id, false);
      setExpenses(prevExpenses => prevExpenses.map(exp => exp._id === updatedExpense._id ? updatedExpense : exp));
      setUnlockDialogOpen(false);
    } catch (error) {
      console.error("Erreur lors du déverrouillage de la dépense:", error);
    }
  };

  const handleEdit = (expense) => {
    setEditingExpense(expense);
  };

  const handleEditSubmit = async (updatedExpense) => {
    try {
      if (!updatedExpense._id) {
        console.error("ID de la dépense manquant");
        return;
      }
      const response = await api.put(`/expenses/${updatedExpense._id}`, updatedExpense);
      setExpenses(prevExpenses => prevExpenses.map(exp => exp._id === updatedExpense._id ? response.data : exp));
      setEditingExpense(null);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la dépense :", error);
    }
  };

  const handleReimbursedToggle = async (id, currentStatus) => {
    setIsLoading(true);
    try {
      const updatedExpense = await updateExpenseStatus(id, !currentStatus);
      setExpenses(prevExpenses => 
        prevExpenses.map(exp => exp._id === id ? updatedExpense : exp)
      );
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut de remboursement:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewReceipt = (url) => {
    setReceiptUrl(url);
  };

  const handlePayment = async (expense) => {
    try {
      await payExpense(expense._id);
      setExpenses(prevExpenses => 
        prevExpenses.map(exp => 
          exp._id === expense._id ? { ...exp, reimbursed: true } : exp
        )
      );
    } catch (error) {
      console.error("Erreur lors du paiement:", error);
    }
  };

  const totalExpenses = expenses.reduce((sum, expense) => sum + Number(expense.amount), 0);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderRadius: 1, overflow: 'hidden', boxShadow: 1 }}>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ flexGrow: 1, overflow: 'auto', maxHeight: 'calc(100% - 60px)' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Montant</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Justificatif</TableCell>
                  <TableCell>Remboursé</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expenses.map((expense) => (
                  <TableRow 
                    key={expense._id}
                    sx={(theme) => ({ 
                      backgroundColor: expense._id === highlightId
                        ? '#A2D6F9'
                        : expense.reimbursed 
                          ? alpha(theme.palette.success.main, 0.3) 
                          : 'inherit',
                      '&:hover': {
                        backgroundColor: expense._id === highlightId
                          ? '#1E96FC'
                          : expense.reimbursed 
                            ? alpha(theme.palette.success.main, 0.4) 
                            : alpha(theme.palette.action.hover, 0.1),
                      }
                    })}
                  >
                    <TableCell>{expense.amount}€</TableCell>
                    <TableCell>{expense.description}</TableCell>
                    <TableCell>{new Date(expense.date).toLocaleDateString()}</TableCell>
                    <TableCell>
                      {expense.justificatif && (
                        <IconButton onClick={() => handleViewReceipt(`${process.env.REACT_APP_API_URL}/${expense.justificatif}`)}>
                          <GetAppIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>
                      <CustomSwitch
                        checked={expense.reimbursed}
                        onChange={() => handleReimbursedToggle(expense._id, expense.reimbursed)}
                        color="primary"
                        disabled={expense.reimbursed}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEdit(expense)} disabled={expense.reimbursed}>
                        <EditIcon color={expense.reimbursed ? "disabled" : "primary"} />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(expense._id)} disabled={expense.reimbursed}>
                        <DeleteIcon color={expense.reimbursed ? "disabled" : "error"} />
                      </IconButton>
                      <IconButton 
                        onClick={() => handlePayment(expense)}
                        disabled={expense.reimbursed}
                        color="primary"
                      >
                        <PaymentIcon />
                      </IconButton>
                      {expense.reimbursed && (
                        <IconButton onClick={() => handleUnlock(expense)}>
                          <LockIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ 
            p: 2, 
            backgroundColor: 'background.paper', 
            borderTop: 1, 
            borderColor: 'divider',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
            <Typography variant="h6">
              Total des dépenses : {totalExpenses.toFixed(2)}€
            </Typography>
          </Box>
        </>
      )}
      <Dialog open={!!editingExpense} onClose={() => setEditingExpense(null)}>
        <DialogTitle>Modifier la dépense</DialogTitle>
        <DialogContent>
          {editingExpense && (
            <ExpenseForm
              initialData={editingExpense}
              onExpenseAdded={handleEditSubmit}
              onCancel={() => setEditingExpense(null)}
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog 
        open={!!receiptUrl} 
        onClose={() => setReceiptUrl(null)} 
        maxWidth="lg" 
        fullWidth
      >
        <DialogTitle>
          Justificatif
          <IconButton
            aria-label="close"
            onClick={() => setReceiptUrl(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {receiptUrl && (
            <iframe 
              src={receiptUrl} 
              width="100%" 
              height="600px" 
              style={{border: 'none'}}
              title="Justificatif" 
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={unlockDialogOpen} onClose={() => setUnlockDialogOpen(false)}>
        <DialogTitle>Déverrouiller la note de frais</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir éditer une note de frais payée ?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUnlockDialogOpen(false)}>Non</Button>
          <Button onClick={handleUnlockConfirm} color="primary">Oui</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ExpenseList;