import React, { useState, useEffect } from 'react';
import { 
  Grid, Card, CardContent, Typography, Box, IconButton, 
  List, ListItem, ListItemText, ListItemAvatar, Avatar, CircularProgress,
  Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  AccountBalance as AccountBalanceIcon,
  Receipt as ReceiptIcon,
  TrendingUp as TrendingUpIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getAccountBalances, getTransactions, getEntriesAndExpenses } from '../services/qontoService';
import { getRecurringExpenses, calculateMonthlyTotal } from '../services/recurringExpenseService';
import { getExpenses } from '../services/expenseService';
import { 
  startOfMonth, endOfMonth, format, subMonths, parseISO, 
  startOfYear, eachMonthOfInterval, eachWeekOfInterval, isSameDay,
  isAfter, isSameMonth, isSameYear, startOfWeek
} from 'date-fns';
import { fr } from 'date-fns/locale';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const IconWrapper = styled(Box)(({ theme, color }) => ({
  backgroundColor: color,
  borderRadius: '50%',
  padding: theme.spacing(2),
  display: 'inline-flex',
  marginBottom: theme.spacing(2),
}));

const Dashboard = () => {
  const [accountBalances, setAccountBalances] = useState({ main: null, tva: null });
  const [transactions, setTransactions] = useState([]);
  const [entriesData, setEntriesData] = useState([]);
  const [expensesData, setExpensesData] = useState([]);
  const [currentMonthExpenses, setCurrentMonthExpenses] = useState(0);
  const [recurringExpensesTotal, setRecurringExpensesTotal] = useState(0);
  const [entriesCumulView, setEntriesCumulView] = useState('monthly');
  const [expensesCumulView, setExpensesCumulView] = useState('monthly');
  const [loading, setLoading] = useState({
    balance: true,
    transactions: true,
    entriesExpenses: true,
    expenses: true,
    recurringExpenses: true
  });
  const [errors, setErrors] = useState({
    balance: null,
    transactions: null,
    entriesExpenses: null,
    expenses: null,
    recurringExpenses: null
  });

  const fetchDashboardData = async () => {
    const now = new Date();
    const endDate = endOfMonth(now);
    const startDate = startOfYear(now); // Fetch data from the start of the year

    // Reset loading and errors
    setLoading({
      balance: true,
      transactions: true,
      entriesExpenses: true,
      expenses: true,
      recurringExpenses: true
    });
    setErrors({
      balance: null,
      transactions: null,
      entriesExpenses: null,
      expenses: null,
      recurringExpenses: null
    });

    // Fetch account balances
    try {
      const balancesData = await getAccountBalances();
      setAccountBalances(balancesData);
    } catch (error) {
      console.error("Error fetching account balances:", error);
      setErrors(prev => ({ ...prev, balance: error.response?.data?.message || "Unable to load balances" }));
    } finally {
      setLoading(prev => ({ ...prev, balance: false }));
    }

    // Fetch transactions
    try {
      const transactionsData = await getTransactions(5);
      console.log("Transactions data received:", transactionsData);
      if (Array.isArray(transactionsData)) {
        setTransactions(transactionsData);
        console.log("Transactions set in state:", transactionsData);
      } else if (transactionsData.transactions && Array.isArray(transactionsData.transactions)) {
        setTransactions(transactionsData.transactions);
        console.log("Transactions set in state:", transactionsData.transactions);
      } else {
        console.error("Unexpected transactions data format:", transactionsData);
        setErrors(prev => ({ ...prev, transactions: "Unexpected data format received" }));
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setErrors(prev => ({ ...prev, transactions: error.response?.data?.message || "Unable to load transactions" }));
    } finally {
      setLoading(prev => ({ ...prev, transactions: false }));
    }

    // Fetch entries and expenses
    try {
      const entriesExpensesData = await getEntriesAndExpenses(startDate, endDate);
      const entries = Array.isArray(entriesExpensesData.entries) ? entriesExpensesData.entries : [];
      const expenses = Array.isArray(entriesExpensesData.expenses) ? entriesExpensesData.expenses : [];
      setEntriesData(entries.length > 0 ? entries : [{ date: format(now, 'yyyy-MM-dd'), amount: 0 }]);
      setExpensesData(expenses.length > 0 ? expenses : [{ date: format(now, 'yyyy-MM-dd'), amount: 0 }]);
    } catch (error) {
      console.error("Error fetching entries and expenses:", error);
      setErrors(prev => ({ ...prev, entriesExpenses: error.response?.data?.message || "Unable to load entries and expenses" }));
    } finally {
      setLoading(prev => ({ ...prev, entriesExpenses: false }));
    }

    // Fetch expenses
    try {
      const expensesData = await getExpenses(startDate, endDate);
      const total = Array.isArray(expensesData.data) 
        ? expensesData.data.reduce((sum, expense) => sum + Number(expense.amount), 0)
        : 0;
      setCurrentMonthExpenses(total);
    } catch (error) {
      console.error("Error fetching expenses:", error);
      setErrors(prev => ({ ...prev, expenses: error.response?.data?.message || "Unable to load expenses" }));
    } finally {
      setLoading(prev => ({ ...prev, expenses: false }));
    }

    // Fetch recurring expenses
    try {
      const recurringExpensesData = await getRecurringExpenses();
      const monthlyTotal = Array.isArray(recurringExpensesData.data)
        ? calculateMonthlyTotal(recurringExpensesData.data)
        : 0;
      setRecurringExpensesTotal(monthlyTotal);
    } catch (error) {
      console.error("Error fetching recurring expenses:", error);
      setErrors(prev => ({ ...prev, recurringExpenses: error.response?.data?.message || "Unable to load recurring expenses" }));
    } finally {
      setLoading(prev => ({ ...prev, recurringExpenses: false }));
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  useEffect(() => {
    console.log("Transactions in state:", transactions);
  }, [transactions]);

  const handleEntriesCumulViewChange = (event) => {
    setEntriesCumulView(event.target.value);
  };

  const handleExpensesCumulViewChange = (event) => {
    setExpensesCumulView(event.target.value);
  };

  const getCumulativeData = (data, view) => {
    const now = new Date();
    let startDate, ticks, groupingFunction;

    switch (view) {
      case 'annual':
        startDate = startOfYear(now);
        ticks = eachMonthOfInterval({ start: startDate, end: now });
        groupingFunction = (date) => startOfMonth(date);
        break;
      case 'quarterly':
        startDate = subMonths(startOfMonth(now), 2);
        ticks = eachWeekOfInterval({ start: startDate, end: now });
        groupingFunction = (date) => startOfWeek(date, { weekStartsOn: 1 });
        break;
      case 'monthly':
      default:
        startDate = startOfMonth(now);
        ticks = eachWeekOfInterval({ start: startDate, end: now });
        groupingFunction = (date) => startOfWeek(date, { weekStartsOn: 1 });
        break;
    }

    const filteredData = data
      .filter(item => isAfter(parseISO(item.date), startDate) || isSameDay(parseISO(item.date), startDate))
      .sort((a, b) => parseISO(a.date) - parseISO(b.date));

    const groupedData = filteredData.reduce((acc, curr) => {
      const groupDate = groupingFunction(parseISO(curr.date));
      const groupKey = format(groupDate, 'yyyy-MM-dd');
      if (!acc[groupKey]) {
        acc[groupKey] = { date: groupKey, amount: 0 };
      }
      acc[groupKey].amount += curr.amount;
      return acc;
    }, {});

    const cumulativeData = Object.values(groupedData).reduce((acc, curr) => {
      const lastAmount = acc.length > 0 ? acc[acc.length - 1].amount : 0;
      return [...acc, { ...curr, amount: lastAmount + curr.amount }];
    }, []);

    // Ensure we have a data point for each tick
    return ticks.map(tick => {
      const tickDate = format(tick, 'yyyy-MM-dd');
      const matchingData = cumulativeData.find(d => d.date === tickDate);
      return matchingData || { 
        date: tickDate, 
        amount: cumulativeData.find(d => isAfter(parseISO(d.date), tick) || isSameDay(parseISO(d.date), tick))?.amount || 0 
      };
    });
  };

  const getXAxisTickFormatter = (view) => {
    switch (view) {
      case 'annual':
        return (date) => format(parseISO(date), 'MMM', { locale: fr });
      case 'quarterly':
      case 'monthly':
      default:
        return (date) => format(parseISO(date), 'dd MMM', { locale: fr });
    }
  };

  const renderLineChart = (data, view, color) => (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={getCumulativeData(data, view)}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="date" 
          tickFormatter={getXAxisTickFormatter(view)}
          interval={view === 'annual' ? 0 : 'preserveStartEnd'}
        />
        <YAxis domain={[0, 'auto']} />
        <Tooltip 
          formatter={(value) => `${value.toFixed(2)}€`}
          labelFormatter={(label) => format(parseISO(label), 'dd MMM yyyy', { locale: fr })}
        />
        <Line type="monotone" dataKey="amount" stroke={color} />
      </LineChart>
    </ResponsiveContainer>
  );

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dashboard
        </Typography>
        <IconButton color="primary" onClick={fetchDashboardData}>
          <RefreshIcon />
        </IconButton>
      </Box>
      <Grid container spacing={3}>
        {/* Carte pour le compte principal Qonto */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent>
              <IconWrapper color="#e3f2fd">
                <AccountBalanceIcon fontSize="large" color="primary" />
              </IconWrapper>
              <Typography variant="h6" component="div" gutterBottom>
                Solde Qonto Principal
              </Typography>
              {loading.balance ? (
                <CircularProgress />
              ) : errors.balance ? (
                <Typography color="error">{errors.balance}</Typography>
              ) : (
                <Typography variant="h4" component="div">
                  {accountBalances.main ? `${accountBalances.main.balance.toFixed(2)}€` : 'N/A'}
                </Typography>
              )}
            </CardContent>
          </StyledCard>
        </Grid>
        {/* Carte pour le compte TVA Qonto */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent>
              <IconWrapper color="#e3f2fd">
                <AccountBalanceIcon fontSize="large" color="primary" />
              </IconWrapper>
              <Typography variant="h6" component="div" gutterBottom>
                Solde Qonto TVA
              </Typography>
              {loading.balance ? (
                <CircularProgress />
              ) : errors.balance ? (
                <Typography color="error">{errors.balance}</Typography>
              ) : (
                <Typography variant="h4" component="div">
                  {accountBalances.tva ? `${accountBalances.tva.balance.toFixed(2)}€` : 'N/A'}
                </Typography>
              )}
            </CardContent>
          </StyledCard>
        </Grid>
        {/* Autres cartes existantes */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent>
              <IconWrapper color="#e8f5e9">
                <ReceiptIcon fontSize="large" color="success" />
              </IconWrapper>
              <Typography variant="h6" component="div" gutterBottom>
                Notes de frais ({format(new Date(), 'MMMM', { locale: fr })})
              </Typography>
              {loading.expenses ? (
                <CircularProgress />
              ) : errors.expenses ? (
                <Typography color="error">{errors.expenses}</Typography>
              ) : (
                <Typography variant="h4" component="div">
                  {`${currentMonthExpenses.toFixed(2)}€`}
                </Typography>
              )}
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent>
              <IconWrapper color="#fff3e0">
                <TrendingUpIcon fontSize="large" color="warning" />
              </IconWrapper>
              <Typography variant="h6" component="div" gutterBottom>
                Frais récurrents
              </Typography>
              {loading.recurringExpenses ? (
                <CircularProgress />
              ) : errors.recurringExpenses ? (
                <Typography color="error">{errors.recurringExpenses}</Typography>
              ) : (
                <Typography variant="h4" component="div">
                  {`${recurringExpensesTotal.toFixed(2)}€`}
                </Typography>
              )}
            </CardContent>
          </StyledCard>
        </Grid>
        
        {/* Nouvelles sections */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>Dernières transactions</Typography>
              {loading.transactions ? (
                <CircularProgress />
              ) : errors.transactions ? (
                <Typography color="error">{errors.transactions}</Typography>
              ) : transactions.length > 0 ? (
                <List>
                  {transactions.map((transaction) => (
                    <ListItem key={transaction.id}>
                      <ListItemAvatar>
                        <Avatar>
                          {transaction.amount < 0 ? <ArrowUpwardIcon color="error" /> : <ArrowDownwardIcon color="success" />}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText 
                        primary={transaction.label} 
                        secondary={format(new Date(transaction.settled_at), 'dd MMM yyyy', { locale: fr })}
                      />
                      <Typography variant="body2" color={transaction.amount < 0 ? "error" : "success"}>
                        {transaction.amount.toFixed(2)}€
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>Aucune transaction récente</Typography>
              )}
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Entrées</Typography>
                <FormControl variant="outlined" size="small">
                  <InputLabel id="entries-cumul-select-label">Vue cumulative</InputLabel>
                  <Select
                    labelId="entries-cumul-select-label"
                    value={entriesCumulView}
                    onChange={handleEntriesCumulViewChange}
                    label="Vue cumulative"
                  >
                    <MenuItem value="monthly">Cumul mensuel</MenuItem>
                    <MenuItem value="quarterly">Cumul trimestriel</MenuItem>
                    <MenuItem value="annual">Cumul annuel</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {loading.entriesExpenses ? (
                <CircularProgress />
              ) : errors.entriesExpenses ? (
                <Typography color="error">{errors.entriesExpenses}</Typography>
              ) : (
                renderLineChart(entriesData, entriesCumulView, "#4caf50")
              )}
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Sorties</Typography>
                <FormControl variant="outlined" size="small">
                  <InputLabel id="expenses-cumul-select-label">Vue cumulative</InputLabel>
                  <Select
                    labelId="expenses-cumul-select-label"
                    value={expensesCumulView}
                    onChange={handleExpensesCumulViewChange}
                    label="Vue cumulative"
                  >
                    <MenuItem value="monthly">Cumul mensuel</MenuItem>
                    <MenuItem value="quarterly">Cumul trimestriel</MenuItem>
                    <MenuItem value="annual">Cumul annuel</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {loading.entriesExpenses ? (
                <CircularProgress />
              ) : errors.entriesExpenses ? (
                <Typography color="error">{errors.entriesExpenses}</Typography>
              ) : (
                renderLineChart(expensesData, expensesCumulView, "#f44336")
              )}
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;