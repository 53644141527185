import api from './api';

export const searchClients = async (term) => {
    try {
      console.log(`Searching clients with term: ${term}`);
      const response = await api.get(`/invoice-ninja/clients/search`, {
        params: {
          term: term,
          status: 'active'
        }
      });
      console.log('Search response:', response.data);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la recherche des clients:", error);
      if (error.response) {
        console.error("Réponse d'erreur:", error.response.data);
      }
      throw error;
    }
  };
  