import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Box, CircularProgress, Alert, Typography } from '@mui/material';
import axios from 'axios';

const ExpenseForm = ({ initialData, onExpenseAdded, onCancel }) => {
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [analyzed, setAnalyzed] = useState(false);
  const [error, setError] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [justificatifPath, setJustificatifPath] = useState('');

  useEffect(() => {
    if (initialData) {
      setAmount(initialData.amount.toString());
      setDescription(initialData.description);
      setDate(initialData.date.split('T')[0]);
      setJustificatifPath(initialData.justificatif);
      setAnalyzed(true);
    }
  }, [initialData]);

  const analyzeFile = async () => {
    if (!file) {
      setError("Veuillez sélectionner un fichier à analyser.");
      return;
    }

    setLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append('justificatif', file);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/expenses/analyze`, 
        formData,
        { 
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          } 
        }
      );
      
      setAmount(response.data.amount ? response.data.amount.toString() : '');
      setDescription(response.data.description || '');
      setDate(response.data.date || '');
      setJustificatifPath(response.data.justificatif);
      setAdditionalInfo({
        supplierName: response.data.supplierName,
        invoiceNumber: response.data.invoiceNumber,
        category: response.data.category,
        subcategory: response.data.subcategory
      });
      
      setAnalyzed(true);
    } catch (error) {
      console.error("Erreur lors de l'analyse du fichier :", error.response ? error.response.data : error.message);
      setError("Erreur lors de l'analyse du fichier. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
  
    try {
      const expenseData = {
        _id: initialData ? initialData._id : null,
        amount: parseFloat(amount),
        description,
        date,
        justificatif: justificatifPath
      };
  
      onExpenseAdded(expenseData);
    } catch (error) {
      console.error("Erreur lors de l'ajout/modification de la dépense :", error.response ? error.response.data : error.message);
      setError("Erreur lors de l'ajout/modification de la dépense. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setAnalyzed(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <input
            type="file"
            onChange={handleFileChange}
          />
          <Button onClick={analyzeFile} disabled={!file || loading}>
            Analyser le fichier
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Montant"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          {analyzed && (
            <Typography variant="caption" display="block" gutterBottom>
              Infos supplémentaires : 
              {additionalInfo.supplierName && ` Fournisseur: ${additionalInfo.supplierName},`}
              {additionalInfo.category && ` Catégorie: ${additionalInfo.category},`}
              {additionalInfo.subcategory && ` Sous-catégorie: ${additionalInfo.subcategory},`}
              {additionalInfo.invoiceNumber && ` N° de facture: ${additionalInfo.invoiceNumber}`}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onCancel} sx={{ mr: 1 }}>Annuler</Button>
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : initialData ? 'Modifier' : 'Ajouter'}
        </Button>
      </Box>
    </Box>
  );
};

export default ExpenseForm;