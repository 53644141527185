import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Drawer,
  Typography,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import axios from 'axios';
import api from '../services/api';
// Styles

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
}));

const DetailCard = styled(Card)(({ theme }) => ({
  height: 'auto',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  margin: '10px',
}));

const DetailCardContent = styled(CardContent)({
  padding: '16px',
  '&:last-child': {
    paddingBottom: '16px',
  },
});

// Components
const DetailItem = ({ icon, label, value }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
      <Box sx={{ mr: 1, color: 'primary.main', mt: 0.5 }}>{icon}</Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 1 }}>{label}:</Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>{value}</Typography>
      </Box>
      <Tooltip title="Copier">
        <IconButton size="small" onClick={handleCopy}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const ClientsPage = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const highlightId = location.state?.highlightId;

  useEffect(() => {
    const fetchClients = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await api.get('/invoice-ninja/clients', {
          params: { status: 'active' },
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log('Clients received:', response.data);
        setClients(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Erreur lors de la récupération des clients:", err);
        setError("Impossible de charger les clients. Veuillez réessayer.");
        setLoading(false);
      }
    };
    

    fetchClients();
  }, []);

  const handleRowClick = (client) => {
    setSelectedClient(client);
  };

  const formatAddress = (client) => {
    const parts = [
      client.address1,
      client.address2,
      client.postal_code && client.city ? `${client.postal_code} ${client.city}` : (client.postal_code || client.city),
      client.state,
      client.country?.name
    ].filter(Boolean);
    return parts.join('\n');
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error" sx={{ m: 2 }}>{error}</Typography>;
  }

  if (clients.length === 0) {
    return <Typography sx={{ m: 2 }}>Aucun client actif trouvé.</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', bgcolor: 'background.default' }}>
      <TableContainer component={Paper} sx={{ flexGrow: 1, m: 2, borderRadius: 2, boxShadow: 3 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>Entreprise</StyledTableCell>
              <StyledTableCell>Nom</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Téléphone</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <StyledTableRow 
                key={client.id} 
                onClick={() => handleRowClick(client)}
                sx={(theme) => ({
                  backgroundColor: client.id === highlightId ? '#A2D6F9' : 'inherit',
                  '&:hover': {
                    backgroundColor: client.id === highlightId ? '#1E96FC' : theme.palette.action.hover,
                  }
                })}
              >
                <TableCell>{client.name}</TableCell>
                <TableCell>{`${client.contacts[0]?.first_name || ''} ${client.contacts[0]?.last_name || ''}`}</TableCell>
                <TableCell>{client.contacts[0]?.email || ''}</TableCell>
                <TableCell>{client.contacts[0]?.phone || ''}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Drawer
        anchor="right"
        open={Boolean(selectedClient)}
        onClose={() => setSelectedClient(null)}
        PaperProps={{
          sx: { width: 533, p: 3, bgcolor: 'background.paper', zIndex: (theme) => theme.zIndex.drawer + 2 }
        }}
      >
        {selectedClient && (
          <Box>
            <Typography variant="h5" gutterBottom color="primary" sx={{ mb: 3 }}>
              Détails du client
            </Typography>
            <DetailCard>
              <DetailCardContent>
                <DetailItem icon={<BusinessIcon />} label="Entreprise" value={selectedClient.name} />
              </DetailCardContent>
            </DetailCard>
            <DetailCard>
              <DetailCardContent>
                <DetailItem 
                  icon={<PersonIcon />} 
                  label="Nom" 
                  value={`${selectedClient.contacts[0]?.first_name || ''} ${selectedClient.contacts[0]?.last_name || ''}`} 
                />
              </DetailCardContent>
            </DetailCard>
            <DetailCard>
              <DetailCardContent>
                <DetailItem icon={<EmailIcon />} label="Email" value={selectedClient.contacts[0]?.email || ''} />
              </DetailCardContent>
            </DetailCard>
            <DetailCard>
              <DetailCardContent>
                <DetailItem icon={<PhoneIcon />} label="Téléphone" value={selectedClient.contacts[0]?.phone || ''} />
              </DetailCardContent>
            </DetailCard>
            <DetailCard>
              <DetailCardContent>
                <DetailItem icon={<HomeIcon />} label="Adresse" value={formatAddress(selectedClient)} />
              </DetailCardContent>
            </DetailCard>
            <DetailCard>
              <DetailCardContent>
                <DetailItem icon={<FingerprintIcon />} label="ID Client" value={selectedClient.id} />
              </DetailCardContent>
            </DetailCard>
          </Box>
        )}
      </Drawer>
    </Box>
  );
};

export default ClientsPage;