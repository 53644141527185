import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, CircularProgress, Paper, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Add as AddIcon, ChevronLeft, ChevronRight } from '@mui/icons-material';
import ExpenseForm from '../components/ExpenseForm';
import ExpenseList from '../components/ExpenseList';
import { getExpenses, addExpense } from '../services/expenseService';
import { useLocation } from 'react-router-dom';
import { format, addMonths, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { fr } from 'date-fns/locale';

const MonthSelector = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

const ExpensesPage = () => {
  const location = useLocation();
  const highlightId = location.state?.highlightId;
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  useEffect(() => {
    fetchExpenses();
  }, [currentMonth]);

  const fetchExpenses = async () => {
    try {
      setLoading(true);
      const start = startOfMonth(currentMonth);
      const end = endOfMonth(currentMonth);
      const response = await getExpenses(start, end);
      setExpenses(response.data);
      setError(null);
    } catch (err) {
      console.error("Erreur lors de la récupération des dépenses:", err);
      setError("Impossible de charger les dépenses. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const handleExpenseAdded = async (newExpenseData) => {
    try {
      setLoading(true);
      const response = await addExpense(newExpenseData);
      setExpenses(prevExpenses => [response.data, ...prevExpenses]);
      setIsFormOpen(false);
      setError(null);
    } catch (err) {
      console.error("Erreur lors de l'ajout de la dépense:", err);
      setError("Impossible d'ajouter la dépense. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousMonth = () => {
    setCurrentMonth(prevMonth => subMonths(prevMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(prevMonth => addMonths(prevMonth, 1));
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', padding: 3 }}>
      <Box sx={{ 
        mb: 3, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderRadius: 1,
        padding: 2,
        boxShadow: 1
      }}>
        <Typography variant="h5" component="h2">Notes de frais</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          onClick={() => setIsFormOpen(true)}
        >
          Ajouter une dépense
        </Button>
      </Box>
      <MonthSelector>
        <IconButton onClick={handlePreviousMonth}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h6">
          {format(currentMonth, 'MMMM yyyy', { locale: fr }).replace(/^\w/, c => c.toUpperCase())}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <ChevronRight />
        </IconButton>
      </MonthSelector>
      {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {loading ? (
          <CircularProgress sx={{ m: 'auto' }} />
        ) : (
          <ExpenseList expenses={expenses} setExpenses={setExpenses} highlightId={highlightId}/>
        )}
      </Box>
      <Dialog open={isFormOpen} onClose={() => setIsFormOpen(false)}>
        <DialogTitle>Ajouter une dépense</DialogTitle>
        <DialogContent>
          <ExpenseForm 
            onExpenseAdded={handleExpenseAdded}
            onCancel={() => setIsFormOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ExpensesPage;