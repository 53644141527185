import api from './api';

export const getExpenses = (startDate, endDate) => {
  const company = JSON.parse(localStorage.getItem('company'));
  if (!company) {
    throw new Error('Aucune entreprise trouvée');
  }
  let params = { company: company._id };
  if (startDate && endDate) {
    params.startDate = startDate.toISOString();
    params.endDate = endDate.toISOString();
  }
  return api.get('/expenses', { params });
};

export const addExpense = (expenseData) => api.post('/expenses', expenseData);

export const deleteExpense = (id) => api.delete(`/expenses/${id}`);

export const searchExpenses = async (term) => {
  try {
    const response = await api.get(`/expenses/search?term=${term}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la recherche des dépenses:", error);
    throw error;
  }
};

export const payExpense = (id) => api.post(`/expenses/${id}/pay`);

export const updateExpenseStatus = async (id, reimbursed) => {
  try {
    const response = await api.patch(`/expenses/${id}`, { reimbursed });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du statut de la dépense:", error);
    throw error;
  }
};