import React, { useState } from 'react';
import { 
  Box, Drawer, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, 
  IconButton, Typography, useMediaQuery, BottomNavigation, BottomNavigationAction,
  Container, Paper, Divider
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { 
  Dashboard as DashboardIcon, TableChart as TablesIcon, Receipt as BillingIcon,
  ExitToApp as LogoutIcon, Settings as SettingsIcon, Notifications as NotificationsIcon,
  NoteAdd as ExpenseIcon, People as ClientsIcon, Link as LinkIcon
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import GlobalSearch from './GlobalSearch';

const drawerWidth = 240;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#ffffff',
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderRadius: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    borderRadius: '12px',
    margin: '16px',
    width: `calc(100% - ${drawerWidth + 48}px)`,
    marginLeft: `${drawerWidth + 16}px`,
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    borderRight: 'none',
    boxShadow: '2px 0 5px rgba(0, 0, 0, 0.05)',
    height: 'calc(100% - 32px)',
    margin: '16px 0 16px 16px',
    borderRadius: '12px',
    padding: theme.spacing(2),
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  marginTop: 56, // Hauteur de l'AppBar
  marginBottom: 56, // Hauteur de la BottomNavigation
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
    marginBottom: 0,
  },
}));

const Layout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Supprime le token
    navigate('/login'); // Redirige vers la page de connexion
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: 'Tableau de bord', icon: <DashboardIcon />, path: '/dashboard' },
    { text: 'Notes de frais', icon: <ExpenseIcon />, path: '/expenses' },
    { text: 'Frais récurrents', icon: <TablesIcon />, path: '/recurring-expenses' },
    { text: 'Devis', icon: <BillingIcon />, path: '/devis' },
    { text: 'Clients', icon: <ClientsIcon />, path: '/clients' },
    { text: 'Ressources', icon: <LinkIcon />, path: '/resources' },
  ];

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <List>
        {menuItems.map((item) => (
          <StyledListItem 
            button 
            key={item.text} 
            onClick={() => navigate(item.path)}
            selected={location.pathname === item.path}
          >
            <ListItemIcon sx={{ color: 'primary.main' }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </StyledListItem>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} /> {/* Cet élément poussera le bouton de déconnexion vers le bas */}
      <Divider sx={{ my: 2 }} />
      <StyledListItem button onClick={handleLogout}>
        <ListItemIcon sx={{ color: 'primary.main' }}><LogoutIcon /></ListItemIcon>
        <ListItemText primary="Déconnexion" />
      </StyledListItem>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: '#f5f5f5' }}>
      <StyledAppBar position="fixed">
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            DimDapp
          </Typography>
          <GlobalSearch />
          <IconButton color="inherit">
            <NotificationsIcon />
          </IconButton>
          <IconButton color="inherit" onClick={() => navigate('/settings')}>
            <SettingsIcon />
          </IconButton>
        </Toolbar>
      </StyledAppBar>
      
      {!isMobile && (
        <StyledDrawer variant="permanent" open>
          {drawer}
        </StyledDrawer>
      )}
      
      {isMobile && (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth,
              backgroundColor: '#ffffff',
              boxShadow: '2px 0 5px rgba(0, 0, 0, 0.05)',
            },
          }}
        >
          {drawer}
        </Drawer>
      )}
      
      <ContentWrapper component="main">
        <Paper elevation={0} sx={{ p: 3, borderRadius: 2, boxShadow: '0px 2px 8px rgba(0,0,0,0.05)', marginLeft: '16px' }}>
          {children}
        </Paper>
      </ContentWrapper>
      
      {isMobile && (
        <Paper 
        sx={{ 
          position: 'fixed', 
          bottom: 0, 
          left: 0, 
          right: 0, 
          zIndex: 1000,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          overflow: 'hidden',
          boxShadow: 3
        }} 
        elevation={3}
      >
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              navigate(menuItems[newValue].path);
            }}
            showLabels
          >
            {menuItems.slice(0, 5).map((item, index) => (
              <BottomNavigationAction 
                key={item.text} 
                label={item.text} 
                icon={item.icon}
                sx={{
                  height: 56,
                  '& .MuiBottomNavigationAction-root': {
                    minWidth: 'auto',
                    padding: '6px 0',
                  },
                  '& .MuiBottomNavigationAction-label': {
                    fontSize: '0.625rem',
                  },
                }}
              />
            ))}
          </BottomNavigation>
        </Paper>
      )}
    </Box>
  );
};

export default Layout;