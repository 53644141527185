import api from './api';

export const getAccountBalances = async () => {
  try {
    const response = await api.get('/qonto/balance');
    return response.data;
  } catch (error) {
    console.error('Error fetching account balances:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getTransactions = async (limit = 5) => {
  try {
    const response = await api.get(`/qonto/transactions?limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching transactions:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getEntriesAndExpenses = async (startDate, endDate) => {
  try {
    const response = await api.get('/qonto/entries-expenses', {
      params: { startDate, endDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching entries and expenses:', error.response ? error.response.data : error.message);
    throw error;
  }
};