import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Paper, Grid, Card, CardContent, CardMedia, 
  Container, Tooltip, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import api from '../services/api';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
  cursor: 'pointer',
}));

const ServiceLogo = styled(CardMedia)(({ theme }) => ({
  paddingTop: '75%', // 4:3 aspect ratio
  backgroundSize: 'contain',
  backgroundPosition: 'center',
}));

const DetailItem = ({ icon, label, value }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Box sx={{ mr: 2, color: 'primary.main' }}>{icon}</Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{label}:</Typography>
        <Typography variant="body2">{value}</Typography>
      </Box>
      <Tooltip title="Copier">
        <IconButton size="small" onClick={handleCopy}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const services = [
  { name: 'Nextcloud', logo: 'https://th.bing.com/th/id/OIP.kdRGtS8C1btGrz3rGtu2nwHaDr?rs=1&pid=ImgDetMain', url: 'https://cloud.dimdapp.fr' },
  { name: 'Invoice Ninja', logo: 'https://www.cloudfindr.co/wp-content/uploads/2021/05/Invoice-Ninja-Logo.png', url: 'https://invoicingninja.captain.dimdapp.com' },
  { name: 'Qonto', logo: 'https://s3-us-west-2.amazonaws.com/public.notion-static.com/14bb9791-d014-42ac-a810-9e7f24aa58f8/qonto-notion-logo.png', url: 'https://qonto.com/fr' },
  { name: 'Stirling PDF', logo: 'https://pdf.dimdapp.fr/favicon.svg', url: 'https://pdf.dimdapp.fr/login?lang=fr_FR' },
  { name: 'DocuSeal', logo: 'https://repocloud.io/static/apps/docuseal/assets/logo.png', url: 'https://signature.captain.dimdapp.com/' },
  // Ajoutez d'autres services ici
];

const ResourcesPage = () => {
  const [company, setCompany] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await api.get('/company');
        setCompany(response.data);
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };
    fetchCompanyData();
  }, []);

  const handleServiceClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Ressources
      </Typography>
      
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Informations de l'entreprise
        </Typography>
        {company && (
          <Box>
            <DetailItem icon={<BusinessIcon />} label="Nom" value={company.name} />
            <DetailItem icon={<HomeIcon />} label="Adresse" value={company.address} />
            <DetailItem icon={<FingerprintIcon />} label="SIRET" value={company.siret} />
            <DetailItem icon={<AccountBalanceIcon />} label="IBAN" value={company.iban} />
          </Box>
        )}
      </Paper>

      <Grid container spacing={2}>
        {services.map((service) => (
          <Grid item xs={12} sm={2.4} key={service.name}>
            <StyledCard onClick={() => handleServiceClick(service.url)}>
              <ServiceLogo
                image={service.logo}
                title={service.name}
              />
              <CardContent>
                <Typography variant="h6" component="div" align="center">
                  {service.name}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ResourcesPage;