import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField, Button, Typography, Box, Avatar, CircularProgress, Alert,
  Paper, Grid, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import api from '../services/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const SettingsPage = () => {
  const [user, setUser] = useState({ firstName: '', lastName: '', email: '' });
  const [company, setCompany] = useState({ name: '', address: '', siret: '', iban: '' });
  const [qontoConfig, setQontoConfig] = useState({ QONTO_API_IDENTIFIER: '', QONTO_API_SECRET: '', QONTO_IBAN: '' });
  const [passwords, setPasswords] = useState({ currentPassword: '', newPassword: '', confirmNewPassword: '' });
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '', password: '' });

  const fetchQontoConfig = useCallback(async () => {
    try {
      const response = await api.get('/config');
      const qontoConfigs = response.data.filter(config => 
        ['QONTO_API_IDENTIFIER', 'QONTO_API_SECRET', 'QONTO_IBAN'].includes(config.key)
      );
      setQontoConfig(qontoConfigs.reduce((acc, config) => {
        acc[config.key] = config.value;
        return acc;
      }, {
        QONTO_API_IDENTIFIER: '',
        QONTO_API_SECRET: '',
        QONTO_IBAN: ''
      }));
    } catch (error) {
      console.error('Error fetching Qonto config:', error);
      setError('Failed to fetch Qonto configuration');
    }
  }, []);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await api.get('/user/profile');
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data');
    }
  }, []);
  
  const checkAdminStatus = useCallback(async () => {
    try {
      const response = await api.get('/user/status');
      setIsAdmin(response.data.isAdmin);
      if (response.data.isAdmin) {
        await fetchQontoConfig();
        await fetchUsers();
      }
    } catch (error) {
      console.error('Error checking admin status:', error);
      setError('Failed to check admin status');
    }
  }, [fetchQontoConfig]);

  const fetchCompanyData = useCallback(async () => {
    try {
      const response = await api.get('/company');
      setCompany(response.data);
    } catch (error) {
      console.error('Error fetching company data:', error);
      setError('Failed to fetch company data');
    }
  }, []);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await api.get('/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users');
    }
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      setError(null);
      try {
        await Promise.all([fetchUserData(), checkAdminStatus(), fetchCompanyData()]);
      } catch (error) {
        console.error('Error initializing data:', error);
        setError('Failed to load initial data');
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, [fetchUserData, checkAdminStatus, fetchCompanyData]);

  const handleChange = (setter) => (key, value) => {
    setter(prev => ({ ...prev, [key]: value }));
  };

  const handleUserChange = handleChange(setUser);
  const handleCompanyChange = handleChange(setCompany);
  const handleQontoConfigChange = handleChange(setQontoConfig);
  const handlePasswordChange = handleChange(setPasswords);
  const handleNewUserChange = handleChange(setNewUser);

  const saveData = async (apiEndpoint, data, successMessage) => {
    try {
      await api.put(apiEndpoint, data);
      setSuccessMessage(successMessage);
    } catch (error) {
      console.error(`Error saving ${apiEndpoint} data:`, error);
      setError(`Failed to save ${apiEndpoint} data`);
    }
  };

  const saveUserData = () => saveData('/user/profile', user, 'User data saved successfully');
  const saveCompanyData = () => saveData('/company', company, 'Company data saved successfully');

  const saveQontoConfig = useCallback(async () => {
    try {
      await Promise.all(
        Object.entries(qontoConfig).map(([key, value]) => 
          api.post('/config', { key, value })
        )
      );
      setSuccessMessage('Qonto configuration saved successfully');
      await fetchQontoConfig();
    } catch (error) {
      console.error('Error saving Qonto config:', error);
      setError('Failed to save Qonto configuration');
    }
  }, [qontoConfig, fetchQontoConfig]);

  const changePassword = async () => {
    if (passwords.newPassword !== passwords.confirmNewPassword) {
      setError('New passwords do not match');
      return;
    }
    try {
      await api.put('/user/change-password', {
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword
      });
      setSuccessMessage('Password changed successfully');
      setPasswords({ currentPassword: '', newPassword: '', confirmNewPassword: '' });
    } catch (error) {
      console.error('Error changing password:', error);
      setError('Failed to change password');
    }
  };

  const addUser = async () => {
    try {
      await api.post('/users', newUser);
      setSuccessMessage('User added successfully');
      setNewUser({ firstName: '', lastName: '', email: '', password: '' });
      fetchUsers();
    } catch (error) {
      console.error('Error adding user:', error);
      setError('Failed to add user');
    }
  };

  if (loading) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h5" gutterBottom>Paramètres</Typography>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Avatar src={user.avatar} sx={{ width: 100, height: 100, mb: 2, alignSelf: 'center' }} />
              {['firstName', 'lastName', 'email'].map((field) => (
                <TextField
                  key={field}
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  value={user[field] || ''}
                  onChange={(e) => handleUserChange(field, e.target.value)}
                  fullWidth
                  margin="normal"
                />
              ))}
              <Box sx={{ flexGrow: 1 }} />
              <Button onClick={saveUserData} variant="contained" color="primary" sx={{ mt: 2 }}>
                Sauvegarde des données utilisateur
              </Button>
            </Box>
          </StyledPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h5" gutterBottom>Changer le mot de passe</Typography>
            {['currentPassword', 'newPassword', 'confirmNewPassword'].map((field) => (
              <TextField
                key={field}
                label={field.split(/(?=[A-Z])/).join(' ')}
                value={passwords[field]}
                onChange={(e) => handlePasswordChange(field, e.target.value)}
                fullWidth
                margin="normal"
                type="password"
              />
            ))}
            <Button onClick={changePassword} variant="contained" color="primary" sx={{ mt: 2 }}>
              Changer le mot de passe
            </Button>
          </StyledPaper>
        </Grid>

        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h5" gutterBottom>Entreprise</Typography>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              {['name', 'address', 'siret', 'iban'].map((field) => (
                <TextField
                  key={field}
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  value={company[field] || ''}
                  onChange={(e) => handleCompanyChange(field, e.target.value)}
                  fullWidth
                  margin="normal"
                  multiline={field === 'address'}
                  rows={field === 'address' ? 3 : 1}
                />
              ))}
              <Box sx={{ flexGrow: 1 }} />
              <Button onClick={saveCompanyData} variant="contained" color="primary" sx={{ mt: 2 }}>
                Sauvegarde des données entreprise
              </Button>
            </Box>
          </StyledPaper>
        </Grid>

        {isAdmin && (
          <>
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h5" gutterBottom>Qonto API Configuration</Typography>
                {['QONTO_API_IDENTIFIER', 'QONTO_API_SECRET', 'QONTO_IBAN'].map((field) => (
                  <TextField
                    key={field}
                    label={field.split('_').join(' ')}
                    value={qontoConfig[field] || ''}
                    onChange={(e) => handleQontoConfigChange(field, e.target.value)}
                    fullWidth
                    margin="normal"
                    type={field === 'QONTO_API_SECRET' ? 'password' : 'text'}
                  />
                ))}
                <Button onClick={saveQontoConfig} variant="contained" color="primary" sx={{ mt: 2 }}>
                  Sauvegarde la configuration Qonto
                </Button>
              </StyledPaper>
            </Grid>

            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h5" gutterBottom>Gestion des utilisateurs</Typography>
                <Box sx={{ mb: 2 }}>
                  {['firstName', 'lastName', 'email', 'password'].map((field) => (
                    <TextField
                      key={field}
                      label={field.charAt(0).toUpperCase() + field.slice(1)}
                      value={newUser[field]}
                      onChange={(e) => handleNewUserChange(field, e.target.value)}
                      fullWidth
                      margin="normal"
                      type={field === 'password' ? 'password' : 'text'}
                    />
                  ))}
                  <Button onClick={addUser} variant="contained" color="primary" sx={{ mt: 2 }}>
                    Ajouter un utilisateur
                  </Button>
                </Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Prénom</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Email</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <TableRow key={user._id}>
                          <TableCell>{user.firstName}</TableCell>
                          <TableCell>{user.lastName}</TableCell>
                          <TableCell>{user.email}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </StyledPaper>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default SettingsPage;