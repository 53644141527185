import axios from 'axios';
import api from './api';

const API_URL = 'http://localhost:5000/api'; // Adjust this URL to match your backend API

export const getDevis = async (startDate, endDate) => {
  try {
    const response = await api.get('/devis', {
      params: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    });
    return response.data || [];
  } catch (error) {
    console.error('Error fetching devis:', error);
    return []; // Retournez un tableau vide en cas d'erreur
  }
};

export const addDevis = async (devisData) => {
  try {
    const response = await axios.post(`${API_URL}/devis`, devisData);
    return response.data;
  } catch (error) {
    console.error('Error adding devis:', error);
    throw error;
  }
};

export const updateDevis = async (id, devisData) => {
  try {
    const response = await axios.put(`${API_URL}/devis/${id}`, devisData);
    return response.data;
  } catch (error) {
    console.error('Error updating devis:', error);
    throw error;
  }
};

export const deleteDevis = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/devis/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting devis:', error);
    throw error;
  }
};

// Add more functions as needed