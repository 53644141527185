import React, { useState, useEffect } from 'react';
import { InputBase, Paper, List, ListItem, ListItemText, Popper, ClickAwayListener, IconButton } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { searchExpenses } from '../services/expenseService';
import { searchRecurringExpenses } from '../services/recurringExpenseService';
import { searchClients } from '../services/clientService';

const GlobalSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setSearchResults([]);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (searchTerm) {
      const search = async () => {
        try {
          const [expenseResults, recurringResults, clientResults] = await Promise.all([
            searchExpenses(searchTerm),
            searchRecurringExpenses(searchTerm),
            searchClients(searchTerm)
          ]);
          
          console.log('Résultats de recherche (clients):', clientResults);
  
          const expenseData = Array.isArray(expenseResults) ? expenseResults : [];
          const recurringData = Array.isArray(recurringResults) ? recurringResults : [];
          const clientData = Array.isArray(clientResults) ? clientResults : [];
  
          const combinedResults = [
            ...expenseData.map(e => ({ ...e, type: 'expense' })),
            ...recurringData.map(e => ({ ...e, type: 'recurring' })),
            ...clientData.map(c => ({ ...c, type: 'client' }))
          ];
          
          setSearchResults(combinedResults);
        } catch (error) {
          console.error("Erreur lors de la recherche:", error);
          setSearchResults([]);
        }
      };
      search();
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);
  

  const handleResultClick = (result) => {
    if (result.type === 'expense') {
      navigate('/expenses', { state: { highlightId: result._id } });
    } else if (result.type === 'recurring') {
      navigate('/recurring-expenses', { state: { highlightId: result._id } });
    } else if (result.type === 'client') {
      navigate('/clients', { state: { highlightId: result.id } });
    }
    setSearchTerm('');
    setSearchResults([]);
  };

  const open = Boolean(anchorEl) && searchResults.length > 0;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
      <Paper
      component="form"
      sx={{ 
        p: '2px 4px', 
        display: 'flex', 
        alignItems: 'center', 
        width: '100%',
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Rechercher..."
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={(event) => setAnchorEl(event.currentTarget)}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
        </Paper>
        <Popper 
          open={Boolean(anchorEl) && searchResults.length > 0} 
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ zIndex: 1500, width: anchorEl ? anchorEl.clientWidth : undefined }}
        >
          <Paper elevation={3}>
            <List>
              {searchResults.map((result) => (
                <ListItem button key={result._id || result.id} onClick={() => handleResultClick(result)}>
                  <ListItemText 
                    primary={result.description || result.name} 
                    secondary={`${result.amount ? result.amount + '€ - ' : ''}${
                      result.type === 'expense' ? 'Dépense' : 
                      result.type === 'recurring' ? 'Frais récurrent' : 
                      'Client'
                    }`} 
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default GlobalSearch;